import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						author {
							name
							bio
							contacts {
								twitter								
								github
								codepen
							}
							photo
						}
						copyright
						description
						menu {
							label
							path
						}
						title
						siteUrl
						image
						facebookAppId
						googleAdsense {
							client
							slot {
								indexTop
								indexBottom
								singleTop
								singleBottom
								singleArticle
							}
						}
					}
				}
			}
		`
	)

	return site.siteMetadata
}

export default useSiteMetadata