const ICONS = {
  TWITTER: {
    prefix: 'fab',
    iconName: 'twitter'
  },
  FACEBOOK: {
    prefix: 'fab',
    iconName: 'facebook'
  },
  GITHUB: {
    prefix: 'fab',
    iconName: 'github'
  },
  FEEDLY: {
    prefix: '',
    iconName: 'feedly'
  },
  HATENABOOKMARK: {
    prefix: '',
    iconName: 'hatenabookmark'
  },
  POCKET: {
    prefix: 'fab',
    iconName: 'get-pocket'
  },
  LINE: {
    prefix: 'fab',
    iconName: 'line'
  },
  CODEPEN: {
    prefix: 'fab',
    iconName: 'codepen'
  },
  SEARCH: {
    prefix: 'fas', 
    iconName: 'search'
  }
}

export default ICONS