import React from 'react'
import { Link, withPrefix } from 'gatsby'

import styled from 'styled-components'

import { SiteTitle } from './SiteTitle'
import { Categories } from './Categories'

import { Author } from './Author'
import { Menu } from './Menu'
import { HumburgerMenuButton, HumburgerMenu } from './HumburgerMenu'

import { Copyright } from './Copyright'
import { Contacts } from './Contacts'
import { DarkModeSwitchButton } from './DarkModeSwitchButton'
//import Searchform from './Searchform'

import useSiteMetadata from '../hooks/useSiteMetadata'
import useCategoriesList from '../hooks/useCategoriesList'
import useHumburgerMenu from '../hooks/useHumburgerMenu'



const StyledHeader = styled.header`
  background-color: var(--header-bg-color);//white;
  //border-radius: .2em;
    //background-color:#705E78;
    //background-color:#E6F3FF;
    //background-color: #FFEC67;
   // background-color: #D0D2F2;
  //border: 4px solid var(--text-color);

  //border-radius: .4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;//overflow-y: hidden;
  padding: 2em;
  transition: all linear 100ms;
/* &::-webkit-scrollbar {
     
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    
  }
  &::-webkit-scrollbar-thumb {
      background-color: #777;
      border-radius: 10px;

  }
  &::-webkit-scrollbar-thumb:hover {
      background-color: #777;
  }
  &:hover {
    overflow-y: scroll;
  }*/

  ${({ isSticky }) => isSticky && `
    position: sticky;
    top: 0;
    z-index: 10;
  `}

  @media screen and (max-width: 780px) {
    //display: flex;
 padding: 1em 2em;
    //align-items: center;
    //flex-direction: row;
    //justify-content: space-between;
  }

  & a {
    //color: inherit;
    text-decoration: none;
  }
  
  & .site-title {
    @media screen and (max-width: 780px) {
      margin: 0;
      text-align: left;
    }

    text-align: center;
    margin-bottom: 1em;
    & img {
     // width: 50%;
      @media screen and (max-width: 780px) {
        width: 30%;
      }
    }
  }

  & .sidebar-card {
    padding: 2em;
    margin-bottom: 1em;
  }

  & h1 {
    font-size: 1.8em;
    margin: 0;
  }

  & h2 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 1em;
  }
  
  & .author,
  .contacts,
  .copyright {
    @media screen and (max-width: 780px) {
      display: none;
    }
  }

  & .categories {
    &.is-open {      
        opacity: 1;
        
        visibility: visible;
      }
    
   // margin-bottom: 3em;
    & ul {
       list-style: none;
      margin: 0;
    }
    & h2 {
     // font-weight: bold;
      text-align: left;
    }
    & .category {
     //border-bottom: 2px solid var(--text-color);
      //border-radius: .25em;
      //display: flex;
      //justify-content: space-between;
//font-weight: bold;
      //padding: 1.4em 0;
     margin-bottom: .75em;
     text-align: center;

      &-count {
        background-color: var(--accent-color);
        border-radius: .8em;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .6em;
        margin-left: .8em;
        padding: .2em 1em;
        text-align: center;
        width: 4em;
      }
    }
  }

  & .menu {
    display: none;
    font-weight: bold;
  }
  
  & .author {
    flex: 1;
    margin: 0 0 3em;
    //border-top: 4px solid var(--text-color);
   //padding-top: 3em;
  }

  & .dark-mode-switch-button {
    //margin: 0 auto 2em;
    & label {
    //  margin-left: auto;
    //  margin-right: auto;
    }
    @media screen and (max-width: 780px) {
      margin: 0;
    }
  }


  & .contacts {
    margin: auto auto 1em;
  }

  & .copyright {
    color: #b6b6b6;
    font-size: .6em;
   //margin-top: auto;
   // text-align: center;
  }
`



export const Header = ({ className, isSticky, children }) => {
  //const { title, image, author, copyright, menu } = useSiteMetadata()
  //const categories = useCategoriesList()
//const [toggleMenu, isOpen, menuElement] = useHumburgerMenu()

  return (
    <StyledHeader className={className} isSticky={isSticky}>
     {children}
    </StyledHeader>
  )
}


//export default Header
/*

  background-color: var(--brand-color);
  padding: .8em var(--page-padding-horizontal);
  position: sticky;
  top: 0;
  z-index: 1;

  & .header-link {
    color: white;
    text-decoration: none;
  }

  & h1 {
    font-size: 1.2em;
    margin: 0;
  }



  <HumburgerMenu className="humburger-menu">
        <Categories className="categories" categories={categories} />
        <Menu className="menu" menu={menu} />
        </HumburgerMenu>
        <Author className="author" author={author} />
        <HumburgerMenu className="humburger-menu">
        <Categories className="categories" categories={categories} />
        <Menu className="menu" menu={menu} />
        </HumburgerMenu>
        <Author className="author" author={author} />
*/