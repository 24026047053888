import { useCallback, useState, useEffect, useLayoutEffect, useRef } from 'react'
import Cookies from 'js-cookie';

const useDarkModeButton = () => {
	const [isDarkMode, setIsDarkMode] = useState(false)
	const checkboxElement = useRef(null)

	const handleChange = useCallback(e => {


		const btn = e.target
		const body = document.body

		if(btn.checked === true) {
			body.classList.remove("light-theme")
			body.classList.add("dark-theme")

			Cookies.set('darkMode', 'on')

			setIsDarkMode(true)
		}else{
			body.classList.remove("dark-theme")
			body.classList.add("light-theme")

			Cookies.remove('darkMode')
			setIsDarkMode(false)
		}
	})

	useLayoutEffect(() => {
		const darkModeCookie = Cookies.get('darkMode')
		const body = document.body
		const checkbox = checkboxElement.current

		if(darkModeCookie){
			body.classList.remove('light-theme');
	        body.classList.add('dark-theme');
	        checkbox.checked = true
			setIsDarkMode(true)

		}else{
			body.classList.remove('dark-theme');
	        body.classList.add('light-theme');
	        checkbox.checked = false
			setIsDarkMode(false)
		}
	}, [])

	useEffect(() => {
		const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
		const darkModeOn = darkModeMediaQuery.matches
		const body = document.body
		const checkbox = checkboxElement.current

		darkModeMediaQuery.addListener((e) => {
		    const darkModeOn = e.matches;
		    if (darkModeOn) {
		        body.classList.remove('light-theme');
		        body.classList.add('dark-theme');
		        checkbox.checked = true
		        setIsDarkMode(true)
		    } else {
		        body.classList.remove('dark-theme');
		        body.classList.add('light-theme');
		        checkbox.checked = false
			setIsDarkMode(false)
		    }
		});
	}, [])

	return [
		isDarkMode,
		checkboxElement,
		handleChange	
	]
}

export default useDarkModeButton