import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, fas)
//library.add(fas)

const StyledIcon = styled.span`
	color: ${({ color }) => color};
	font-size: ${({ size }) => size};
`

export const Icon = ({ icon, className, color, size, ...props }) => (
	<StyledIcon className="icon" color={color} size={size}>
		{icon.prefix ? <FontAwesomeIcon icon={[icon.prefix, icon.iconName]} className={className} {...props} /> : <span className={`icon-${icon.iconName}`}></span>}
	</StyledIcon>
)

//export default Icon