import { useStaticQuery, graphql } from 'gatsby'

const useCategoriesList = () => {
	const { allContentfulBlogPost } = useStaticQuery(
		graphql`
			query CategoriesListQuery {
				allContentfulBlogPost {
				    group(field: category___name) {
				      fieldValue
				      totalCount
				    }
				  }
			}
		`
	)

	const categories = allContentfulBlogPost.group

	return categories
}

export default useCategoriesList