import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, forwardRef } from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'
import ToggleSwitchButton from './ToggleSwitchButton'

import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"

import useDarkModeButton from '../hooks/useDarkModeButton'

const StyledDarkModeSwitchButton = styled.div`
	display: flex;
	align-items: center;
	font-size: ${({ size }) => size};

	& .icon {
		margin-right: .2em;
	}
	& label {
		& .icons {
			border-radius: 2em;
			//background-color: black;
			display: flex;
			align-items: center;
			justify-content: space-around;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;

			& .icon {
				color: white;
			}
		}
	}
`

/*export const DarkModeSwitchButton = React.memo(({ className, handleChange, checkboxElement }) => {
	

	return(
		<StyledDarkModeSwitchButton className={className}>
			<input id="btn-mode" type="checkbox" onChange={handleChange} ref={checkboxElement} />
			<label htmlFor="btn-mode">
				<div className="icons">
					<Icon className="icon icon-light" icon={faSun} />
					<Icon className="icon icon-dark" icon={faMoon} />
				</div>
			</label>
		</StyledDarkModeSwitchButton>
	)
})
*/

export const DarkModeSwitchButton = forwardRef(({ className, size, handleChange }, ref) => {
	return (
		<StyledDarkModeSwitchButton className={className} size={size}>
			<Icon className="icon icon-dark" icon={faMoon} color="#ffd94f" />
			<ToggleSwitchButton
				className="toggle-switch-button"
				handleChange={handleChange}
				offColor="#bbb"
				onColor="#383896"
				ref={ref}
			/>
		</StyledDarkModeSwitchButton>
	)
})
