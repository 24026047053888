import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledToggleSwitchButton = styled.div`
	& input {
		display: none;
		&:checked + label {
			background-color: ${({onColor}) => onColor};
			&::before {
				//order: 2;
				left: 2em;
			}
		}
	}

	& label {
		background-color: ${({offColor}) => offColor};
		border-radius: 2em;
		cursor: pointer;
		//display: block;
		display: flex;
		align-items: center;
		font-size: ${({ size }) => size};
		justify-content: space-around;
		height: 2em;
		position: relative;
		transition: .2s;// 本体の色
		width: 3.75em;

		&::before {
			background-color: #fff;
			border-radius: 100%;
			content: '';
			display: inline-block;
			height: 1.5em;
			position: absolute;
			left: 0.25em;
			transition: .2s ease-out;
			width: 1.5em;
			z-index: 2;
		}

		&::after {
			background-color: red;
			border-radius: 100%;
			content: '';
			display: inline-block;
			height: 1.5em;
			position: absolute;
			right: .25em;
			visibility: hidden;
			width: 1.5em;
			z-index: 2;
		}
		
		& .icons {
			border-radius: 2em;
			display: flex;
			align-items: center;
			justify-content: space-around;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
		}
	}
`

const ToggleSwitchButton = forwardRef(({ className, handleChange, offColor, onColor, size }, ref) => (
	<StyledToggleSwitchButton
		className={className}
		offColor={offColor}
		onColor={onColor}
		size={size}
	>
		<input id="btn-mode" type="checkbox" onChange={handleChange} ref={ref} />
		<label htmlFor="btn-mode">	
		</label>
	</StyledToggleSwitchButton>
))

export default ToggleSwitchButton