import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'
import getContactHref from '../utils/get-contact-href'

const ContactsComponent = ({ className, contacts }) => (
	<div className={className}>
		<ul className="contacts-list">
			{Object.keys(contacts).map(name => (
				!contacts[name] ? null : (
					<li className="contacts-list-item" key={name}>
						<a
							className={`contacts-list-item-link contacts-list-item-link-${name}`}
							href={getContactHref(name, contacts[name])}
							rel="noopener noreferrer"
							target="_blank"
						>
							<Icon icon={getIcon(name)} />
						</a>
					</li>
				)
			))}
		</ul>
	</div>
)

const StyledContactsComponent = styled(ContactsComponent)`
	& .contacts-list {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		
		&-item {

			border-radius: 100%;
			height: 1.4em;
			margin-right: .8em;
			margin-bottom: 0;
			width: 1.4em;
			
			&-link {
				background-color: #333;
				//border: 2px solid var(--text-color);
				border-radius: 100%;
				color: #fff;//color: var(--text-color);//white;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 1.8em;
				width: 1.8em;
font-size: .8em;
				&-twitter {
					background-color: #55acee;

				}
				&-github {
					background-color: var(--text-color);
				}
				&-codepen {
					background-color: #666;
				}
			}
		}
	}
`

export const Contacts = props => (
	<StyledContactsComponent {...props} />
)

//export default Contacts