import React from 'react'
import styled from 'styled-components'
//import useSiteMetadata from '../hooks/useSiteMetadata'

const StyledPage = styled.main`
	
`

export const Page = ({ className, title, children }) => (
	<StyledPage className={className}>
		<div className="page-inner">
			{ title && <h1 className="page-title">{title}</h1>}
			<div className="page-body">
				{children}
			</div>
		</div>
	</StyledPage>
)
