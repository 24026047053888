import React from 'react';
import { Button } from './Button'

import useHumburgerMenu from '../hooks/useHumburgerMenu'

import styled from 'styled-components'

const StyledHumburgerMenuButton = styled.div`
	font-size: ${({ size }) => size};
	& button {
		border: none;
		outline: none;
		cursor: pointer;
		padding: 0;
	}

	& .humburger-menu-toggle {
		display: none;

		@media screen and (max-width: 780px) {
			background-color: transparent;
			display: block;
		    position:relative;
		    z-index: 20;

		    &-icon {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 2em;
				overflow: hidden;
				width: 2em;

				> span {
					background-color: var(--text-color);
					border-radius: 1px;
					display: block;
					height: 2px;
					opacity: 1;
					transition: all .6s;
					width: 100%;

					&:nth-child(1) {
						transform: ${({ isOpen }) => isOpen && `translateY(calc(1em - .5px)) rotate(45deg)`};
					}
					&:nth-child(2) {
						opacity: ${({ isOpen }) => isOpen && 0};
					}
					&:nth-child(3) {
						transform: ${({ isOpen }) => isOpen && `translateY(calc(-1em + .5px)) rotate(-45deg)`};
					}
				}
			}
		}
	}
}

`

const StyledHumburgerMenu = styled.div`
	@media screen and (max-width: 780px) {
		background-color: var(--bg-color);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: ${({ isOpen }) => isOpen ? 1 : 0};
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: center;
		transition: all .6s ease;
		visibility: ${({ isOpen }) => isOpen ? `visible` : `hidden`};
		z-index: 10;

		a {
			//color: white;
		}
	}
`

export const HumburgerMenuButton = ({ className, handleClick, isOpen, size }) => {
	//const elementRef = useRef(null)
	//const [toggleMenu, elementRef] = useHumburgerMenu()

	return (
		<StyledHumburgerMenuButton className={className} isOpen={isOpen} size={size}>
			<Button className="humburger-menu-toggle" onClick={handleClick}>
				<div className="humburger-menu-toggle-icon">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</Button>
		</StyledHumburgerMenuButton>
	)
}

export const HumburgerMenu = ({ className, isOpen, children }) => {
	return (
		<StyledHumburgerMenu className={className} isOpen={isOpen}>
			{children}
		</StyledHumburgerMenu>
	)
}