import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import Article from '../components/Article';
//import styled from 'styled-components';
import {Header} from '../components/header'
//import Footer from '../components/Footer'
//import Card from '../components/Card';
import {Page} from '../components/Page'

//import { Link } from 'gatsby';

const FixedPage = props => (
	<Layout className={props.className} layout="row">
		<SEO
			title={props.pageContext.pageData.title}
			description={props.pageContext.pageData.excerpt}
		/>		
		<Header className="header" isSticky={true} />
		<Page className="container" title={props.pageContext.pageData.title}>
			<div dangerouslySetInnerHTML={{__html: props.pageContext.pageData.content}} />
		</Page>
	</Layout>
)
/*
const StyledFixedPageComponent = styled(FixedPageComponent)`
	max-width: 66.875rem;
	margin-left: auto;
	margin-right: auto;
	
	@media screen and (max-width: 780px) {
		display: block;
	}
`
*/
/*
const FixedPage = props => (
	<FixedPageComponent {...props} />
)
*/
export default FixedPage