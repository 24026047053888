import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const StyledButton = styled.button`
	${({ link }) => link ?
	`border: none;
	padding: 0;`
	: `background-color: var(--brand-color);
		border: none;
		border-radius: 3px;
		color: white;
		font-size: 1em;
		padding: 1em 2em;
		cursor: pointer;`
	}
	
	& a {
		display: block;
		background-color: var(--brand-color);
		
		border-radius: 3px;
		color: white;
		font-size: 1em;
		padding: 1em 2em;
		cursor: pointer;
		text-decoration: none;
	}
`

export const Button = ({className, link, children, ...props}) => (
	<StyledButton className={className} {...props} link={link}>
		{
			link ? <Link to={link}>{children}</Link>
			: children
		}
	</StyledButton>
)

//export default Button