import ICONS from '../constants/icons'

const getIcon = (name) => {
  let icon;

  switch (name) {
    case 'twitter':
      icon = ICONS.TWITTER;
      break;
    case 'github':
      icon = ICONS.GITHUB;
      break;
    case 'feedly':
      icon = ICONS.FEEDLY;
      break;
    case 'line':
      icon = ICONS.LINE;
      break;
    case 'facebook':
      icon = ICONS.FACEBOOK;
      break;
    case 'get-pocket':
      icon = ICONS.POCKET;
      break;
    case 'hatenabookmark':
      icon = ICONS.HATENABOOKMARK;
      break;
    case 'codepen':
      icon = ICONS.CODEPEN;
      break;
    case 'search':
      icon = ICONS.SEARCH;
    default:
      icon = {};
      break;
  }

  return icon;
}

export default getIcon