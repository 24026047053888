import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'

const StyledSiteTitle = styled.h1`

	margin: 0;
	> a {
		display: block;
	}
	& img {
		//border-radius: .2em;
		//background-color:#705E78;
		//background-color:#E6F3FF;
		//background-color: #FFEC67;
		//background-color: #D0D2F2;
		//border-bottom: 3px solid #333;
		display: block;
		margin: 0;
	}

`

const SiteLogoBg = styled.div`
background-image: ${({logoUrl}) => logoUrl && `url(${logoUrl})`};
@media screen and (max-width: 780px) {
        //background-size: cover;
        //background-repeat: no-repeat;
        height: 4em;
        width: 4em;
      }
background-size: cover;
background-repeat: no-repeat;
		height: 8em;
	width: 8em;
`

export const SiteTitle = ({ className, title, linkTo, logoUrl }) => (
	<StyledSiteTitle className={className} logoUrl={logoUrl}>
		<Link to={linkTo || '/'}>
			{
				logoUrl ? <img src={logoUrl} alt={title} /> : title
			}
		</Link>
	</StyledSiteTitle>
)
//<SiteLogoBg className="site-logo-bg" logoUrl={logoUrl} />