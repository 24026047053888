import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Icon } from './Icon'
import { faFolder } from "@fortawesome/free-solid-svg-icons"

const StyledCategories = styled.div`

  & h3 {
    font-size: 1.2em;
  }
& ul {
  //display: flex;
  //flex-wrap: wrap;
}
  & .category {

    //width: 50%;
    //color: #777;
    font-size: .8em;
    font-weight: 700;
    //border-bottom: 1px solid #ddd;
width: 100%;
  }

  & .fa-folder {
      margin-right: 1em;
  }
`

export const Categories = ({ className, categories }) => (
  <StyledCategories className={className}>
	<ul>
      {categories.map((c) => (
        <li className={`category category-${c.fieldValue}`} key={c.fieldValue}>
          
          <Link to={`/category/${encodeURIComponent(c.fieldValue)}`}>
            {`${c.fieldValue} (${c.totalCount})`}
          </Link>
          
        </li>
      ))}
    </ul>
    </StyledCategories>
)

//<Icon icon={faFolder} />
//export default Categories
//<span className="category-count">{c.totalCount}</span>