import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components'

const MenuComponent = ({ className, menu }) => (
	<nav className={className}>
		<ul className="menu-list">
			{menu.map((item, index) => (
				<li key={index} className="menu-list-item">
					<Link to={item.path} className="menu-list-item-link">
						{item.label}
					</Link>
				</li>
			))}
		</ul>
	</nav>
);

const StyledMenuComponent = styled(MenuComponent)`
	ul {
		list-style: none;
		margin: 0;
	}
`

export const Menu = props => (
	<StyledMenuComponent {...props} />
)

//export default Menu;