import React from 'react'
import { Link, withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Contacts } from './Contacts'

const StyledAuthor = styled.div`
	display: flex;
	flex-direction: column;
	//align-items: center;
	
	& .author-photo {
		border-radius: 100%;
		//border: 4px solid var(--text-color);
		width: 140px
	}
	& .author-name {
		font-size: 1.2em;
		margin-bottom: .6em;
		text-align: left;
	}
	& .author-bio {
		color: #888;
		margin-bottom: 1em;
		font-size: .8em;
	}
	& .author-contacts {
	}
`

export const Author = ({ className, author }) => (
	<StyledAuthor className={className}>
		<Link to="/">
			<img 
				src={withPrefix(author.photo)}
				className="author-photo"
				alt={author.name}
			/>
		</Link>
		<h3 className="author-name">
			{author.name}			
		</h3>
		<p className="author-bio">{author.bio}</p>
		<Contacts className="author-contacts" contacts={author.contacts} />
	</StyledAuthor>
)

//export default Author;