import { useCallback, useRef, useEffect, useState } from 'react';
import useResizeObserver from './useResizeObserver';

const useHumburgerMenu = () => {
	const humburgerMenuRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)

	const toggleMenu = useCallback((e) => {
		if(isOpen) {
			setIsOpen(false)
		}else{
			setIsOpen(true)
		}
		
		document.querySelector('html').classList.toggle('is-open')
		//document.body.style.overflowY = 'hidden'
		//e.target.classList.toggle('is-open')
		//humburgerMenuRef.current.classList.toggle('is-open')


		//humburgerMenuRef.current.lastElementChild.style.transition = '';
		/*
		if(!humburgerMenuRef.current.classList.contains('is-open')) {
			humburgerMenuRef.current.classList.add('is-close')
		}else{
			humburgerMenuRef.current.classList.remove('is-close')
		}
		*/
		
	}, [isOpen])

	useEffect(() => {

			document.querySelector('html').classList.remove('is-open')
		}, [])

	/*useResizeObserver(
		[humburgerMenuRef],
		entries => {

		}
	);*/

	/*useEffect(() => {
		window.addEventListener('resize', e => {
			console.log(humburgerMenuRef.current.lastElementChild)
			//if(humburgerMenuRef.current.lastElementChild) {
			humburgerMenuRef.current.lastElementChild.style.transition = 'none'
			//}
		})

		return () => {
			document.body.style.overflowY = ''
		}
	}, [])*/

	return [toggleMenu, isOpen, humburgerMenuRef]
}

export default useHumburgerMenu